




































import { Component, Prop, Vue } from 'vue-property-decorator'

import { MegaMenuNavigation as MegaMenuNavigationItem } from '../MegaMenu.contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component({ name: 'MegaMenuNavigation' })
export class MegaMenuNavigation extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  public navCategoryItemAsHeading?: boolean

  @Prop({ type: Boolean, required: true })
  public hasColumnAdditionalHeaderOnBottom!: MegaMenuNavigationItem

  @Prop({ type: Object, required: false, default: () => false })
  public navigation!: MegaMenuNavigationItem

  get badge (): string | undefined {
    return this.navigation.header?.link.badge
  }

  get isBadgeValid (): boolean {
    const dateString = this.navigation.header?.link.badgeExpiresAt

    if (!dateString) {
      return false
    }

    return new Date(dateString) >= new Date()
  }
}

export default MegaMenuNavigation
